<template>
  <div>
    <div class="h4">Give away</div>
    <div class="text">
      On July 30 we started token conversion from LQFv1 to LQFv2. To celebrate the launch of the new token, we're distributing 100k LQF to event supporters.
    </div>

    <div class="mt-3 caption text">
      Liquifi - digital asset exchange, decentralized and user community-owned. The project is working from October 2020. Liquifi team successfully launched DEX and Farming protocol with high APY. Liquifi v1.0 has 5860 holders, 300 liquidity providers and also an active community with 50K+ subscribers. The new version Liquifi v2.0 will have the possibility to invest in single tokens and get fees from any token pair. LQF v2.0 token will have every block farming rewards, auto LQF farming pool and yield pools and a decentralized and controllable mint/burn schedule. The Liquifi team created a special app for the $LQFv1 to $LQFv2 token conversion. Buy LQFv1 and become a part of the conversion right now!
    </div>

    <b-button
        block
        variant="primary"
        class="button-flat mt-3"
        v-on:click="onNextStep"
        :disabled="false"
    >Next</b-button>
  </div>
</template>

<script>
export default {
  name: 'IntroductionStep',

  methods: {
    onNextStep() {
      this.$emit('next');
    },
  }
};
</script>

<style scoped lang="scss">

</style>
